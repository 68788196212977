import React from "react";
import styled from '@emotion/styled/macro';


import {useTheme } from "@mui/material";

import ChipContainer from "../ChipContainer";

import { CardActions, CardBody, CardHeader, CardWrapper } from "../../Atoms/Card";
import Calendar from "../../Icons/Calendar";
import Clock from "../../Icons/Clock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatDate from "../../../shared/dateFormatter";
import formatTime from "../../../shared/timeFormatter";
import { faBed, faHospital, faIdCard, faMoneyBillWave, faUser, faUserMd, faUserNurse, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import DocumentosChips from "../Chips/DocumentosChips";
import CirugiasActions from "../CirugiasActions";
import { Link, useLocation } from "react-router-dom";
import { setScrollPosition } from "../../../app/cirugiasReducer";
import { useDispatch } from "react-redux";
import EspecialistaChip from '../Chips/EspecialistaChip';
import FileText from "../../Icons/File-text";
import usePermissions from "../../../hooks/usePermissions";
import { useConfiguration } from "../../../hooks/useConfiguration";
import useDateAndTimeFormatter from "../../../hooks/useDateAndTimeFormatter";
import AlertOctagon from "../../Icons/AlertOctagon";



const CirugiasCardHeader = ({cirugia})=>{
 
    const configuration = useConfiguration();
    const cardHeader = configuration?.cirugias?.cardHeader || "paciente"
    let cardHeaderLabel

    if(cardHeader === "paciente"){

     cardHeaderLabel = (
        <>
            <PacienteName>{`${cirugia.paciente?.firstname} ${cirugia.paciente?.secondname} ${cirugia.paciente?.lastname} ${cirugia.paciente?.secondLastname}`}</PacienteName> 
                    <Subtitle>{cirugia.paciente?.nss ? `NSS: ${cirugia.paciente?.nss}` : "NSS: No disponible"}</Subtitle>
                    <Subtitle>{cirugia.paciente?.cedula ? `Cedula: ${cirugia?.paciente?.cedula}` : "Cedula: No disponible"}</Subtitle>
                    <Subtitle>{`Cotizacion: ${cirugia?.cotizacion}`}</Subtitle>
            </>
        )
    }else if(cardHeader === "doctor"){
        cardHeaderLabel = (
        <>
            <PacienteName>{`${cirugia.doctor?.firstname} ${cirugia.doctor?.secondname} ${cirugia.doctor?.lastname} ${cirugia.doctor?.secondLastname}`}</PacienteName>             
            <Text style={{fontWeight: 600}}>{cirugia.centro?.nombre}</Text>   
            <Subtitle>{`Cotizacion: ${cirugia?.cotizacion}`}</Subtitle>
        </>
        )
    }

    
    return(

        <CardHeader>
            <HeaderContainer>
                <PacienteContainer>
                    {cardHeaderLabel}            
                </PacienteContainer>
                <ChipContainer cirugia={cirugia}/>                                    
            </HeaderContainer>            
        </CardHeader>
    )
}





const CirugiasCard = ({
    cirugia,    
    setOpenModal,
    close,
    setCirugiaId,
    formType,
    setFormType,
    withLinks = false,
    md 
})=>{
        
    const theme = useTheme();
    const dispatch = useDispatch();
    const {formatTime} = useDateAndTimeFormatter()
    const permissions = usePermissions();
    const {canViewFacturacionStages} = permissions;
    const location =useLocation()
    const configuration = useConfiguration();
    const cardHeader = configuration?.cirugias?.cardHeader || "paciente"


    const setScroll= ()=>{
        dispatch(setScrollPosition(window.scrollY))
    }


    let calendarColor = theme.palette.grey[800];

    if(cirugia.info?.agendada && !cirugia.info?.aceptada && !cirugia.info?.suspendia && !cirugia.info?.rechazada){
        calendarColor = theme.palette.warning.light;
    }

    if(cirugia.info?.suspendida){
        calendarColor = theme.palette.error.light;
    }

    if(cirugia.info?.rechazada){
        calendarColor = theme.palette.error.light;
    }

    //link tracks were it is coming from in orer to know if it from the search page so that it can go back to it and not back to the Cirugias page.
 
    let metodoPago
    if(cirugia.pago.tipo === "Ars"){
        metodoPago = cirugia.pago.nombre
    }else if(cirugia.pago.tipo === "Privado"){
        metodoPago = "Privado"
    }else if(cirugia.pago.tipo === "Centro"){
        metodoPago = "Centro"
    }
    

    return(
        <>
        {                    
                        <Card>
                            { withLinks ? 
                                <Link 
                                    to={`/app/cirugias/${cirugia._id}`}
                                    state ={{path:location.pathname, from: 'cirugias'}}                                                                        
                                    onClick={setScroll} 
                                    style={{textDecoration: 'none'}}
                                >                            
                                    <CirugiasCardHeader cirugia={cirugia}/>
                                </Link>
                                :
                            <CirugiasCardHeader cirugia={cirugia}/>
                            }
                            <CardBody>
                                <Container>                            
                                    <Wrapper>
                                        <InfoWrapper>
                                            <Calendar size="18" color={calendarColor}/>
                                            <Text>{cirugia.info?.agendada ? cirugia.info?.fecha && formatDate(cirugia.info?.fecha) : "Pendiente"}</Text>
                                        </InfoWrapper>
                                        <InfoWrapper>
                                            <Clock size="18" color={calendarColor}/>
                                            <Text>{cirugia.info?.agendada ? cirugia.info?.fecha && formatTime(cirugia.info?.fecha) : "Pendiente"}</Text>
                                        </InfoWrapper>
                                        {cardHeader === "paciente" &&
                                            <InfoWrapper>
                                                <FontAwesomeIcon icon={faHospital} size="1x" color= {theme.palette.grey[700]}/>
                                                <Text>{cirugia.centro?.nombre}</Text>
                                            </InfoWrapper>
                                        }
                                        <InfoWrapper>
                                            <FontAwesomeIcon icon={faIdCard} size="1x" color= {theme.palette.grey[700]}/>
                                            <Text>{metodoPago}</Text>
                                            {cirugia.pago?.priorizar && <AlertOctagon size="18" color= {theme.palette.error.light}/>}
                                            
                                        </InfoWrapper>
                                        {!md && <InfoWrapper>
                                            <FontAwesomeIcon icon={faMoneyBillWave} size="1x" color= {theme.palette.grey[700]}/>
                                            <Text>{cirugia.pago?.monto || "No aplica"}</Text>
                                        </InfoWrapper>}
                                        {cardHeader === "paciente" &&
                                            <InfoWrapper>
                                                <FontAwesomeIcon icon={faUserMd} size="1x" color= {theme.palette.grey[700]}/>
                                                <Text>{`${cirugia?.doctor?.firstname} ${cirugia?.doctor?.lastname}`}</Text>
                                            </InfoWrapper>
                                        }
                                        {cardHeader === "doctor" &&
                                            <InfoWrapper>
                                                <FontAwesomeIcon icon={faBed} size="1x" color= {theme.palette.grey[700]}/>
                                                <Text>{`${cirugia?.paciente?.firstname || "Pendiente"} ${cirugia?.paciente?.lastname || ""}`}</Text>
                                            </InfoWrapper>
                                        }
                                    </Wrapper>
                                    <CasaComercial>
                                        {md && <InfoWrapper>
                                            <FontAwesomeIcon icon={faWarehouse} size="1x" color= {theme.palette.grey[700]}/>
                                            <Text>{`${cirugia?.company?.name}`}</Text>
                                        </InfoWrapper>
                                        }
                                        <InfoWrapper>
                                            <FontAwesomeIcon icon={faUserNurse} size="1x" color= {theme.palette.grey[700]}/>
                                            {
                                                md ? 
                                                <Text>{cirugia.especialista?.nombre ? `${cirugia.especialista?.nombre?.firstname} ${cirugia.especialista?.nombre?.lastname}` : 'Pendiente'}</Text>
                                                :
                                                <EspecialistaChip especialista={cirugia.especialista}/> 
                                            }
                                            
                                        </InfoWrapper>                            
                                        <InfoWrapper>
                                            <FontAwesomeIcon icon={faUser} size="1x" color= {theme.palette.grey[700]}/>                                            
                                            <Text>{cirugia?.ventas?.firstname ? `${cirugia?.ventas?.firstname} ${cirugia?.ventas?.lastname}` : "Pendiente"}</Text>
                                        </InfoWrapper>  
                                        <DocumentosChips documentos = {{...cirugia.documentos}}/>
                                        {!md && canViewFacturacionStages && cirugia.info?.realizada && 
                                            <InfoWrapper>
                                                <FileText size={18}/>
                                                <FacturacionWrapper>
                                                    <FacturacionText>{`Transferencia: ${cirugia?.facturacion?.transferencia || 'Pendiente'} `} </FacturacionText>
                                                    <FacturacionText>{`Entrega: ${cirugia?.facturacion?.entrega || 'Pendiente'}`} </FacturacionText>
                                                    <FacturacionText>{`Cierre: ${cirugia?.facturacion?.cierre || 'Pendiente'}`} </FacturacionText>
                                                    <FacturacionText>{`Factura: ${cirugia?.facturacion?.factura || 'Pendiente'}`} </FacturacionText>
                                                </FacturacionWrapper>
                                            </InfoWrapper>}
                                    </CasaComercial>
                                </Container>

                            </CardBody>
                            <StyledCardActions>
                            <CirugiasActions
                                setOpenModal={setOpenModal}
                                cirugiaId={cirugia._id}
                                setCirugiaId={setCirugiaId}
                                formType={formType}
                                setFormType={setFormType}
                                cirugia={cirugia}                                
                            />  
                                
                            </StyledCardActions>
                        </Card>                    
                }
        </>
    )


}


const Card = styled(CardWrapper)`
    margin:0;
    border-radius: 12px;
`

const StyledCardActions = styled(CardActions)`
    border-top: 1px solid ${p=>p.theme.palette.grey[300]};
    margin-top: 16px;
    min-height: revert;
    padding-top: 8px;
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 8px;
`
const PacienteContainer=styled.div`
    display:flex;
    flex-direction: column;
    min-width:50%;
`



const PacienteName = styled.p`
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[800]}    
`

const Subtitle = styled.p`
    margin:0;
    font-size: 0.7rem;
    font-weight: 500;
    color: ${p=>p.theme.palette.grey[800]};    
`
const Container = styled.div`
    display:flex;
    gap: 8px;
    
`


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex:1;
`

const CasaComercial = styled.div`
    display:flex;
    flex-direction:column;
    gap: 8px;
    flex:1;
`

const InfoWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    

`

const Text = styled.p`
    margin: 0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[700]};
`

const FacturacionText = styled.p`
    margin: 0;
    font-size: 0.75rem;
    color: ${p=>p.theme.palette.grey[700]};
`


const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
`


const FacturacionWrapper = styled.div`
    display:flex;
    flex-direction: column;
`


export default CirugiasCard;